<template>
    <div>
        <v-card class="rounded-lg" elevation="0">
            <v-tabs fixed-tabs background-color="#2596be" dark>
                <v-tabs-slider color="#b4dbdb"></v-tabs-slider>
                <v-tab>EQUIPES MÉDICAS</v-tab>

                <v-tab-item>
                    <v-card-text>
                        <v-form ref="searchReceptor">
                            <p class="space-personalized"></p>
                            <v-container>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="10">
                                        <v-text-field color="#086318" label="Pesquisar..." append-icon="mdi-magnify"
                                            placeholder="Nome completo" outlined v-model="search" dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2" v-if="(this.$store.state.profile_id == 1 || this.$store.state.profile_id == 4)">
                                        <v-btn class="rounded-lg" elevation="0"
                                            @click="$router.push('/cadastrar-equipe-medica')" color="#2596be">
                                            <v-icon class="mr-2" color="#FFFFFF">mdi-hospital</v-icon>
                                            <span class="textBtn"> Nova Equipe Médica</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-data-table :headers="headers" :items="equipes"
                        :footer-props="{ 'items-per-page-text': 'Time por Página', }" :page="page"
                        :loading="loading">

                        <template v-slot:[`item.actions`]="{ item }" :resource="item" v-if="(this.$store.state.profile_id == 1 || this.$store.state.profile_id == 4)">
                            <v-icon size="20" class="icon" color="#2596be" align-item="center"
                                @click="$router.push({ path: '/editar-equipe-medica', query: { item: item } })">mdi-lead-pencil
                            </v-icon>
                            <v-icon size="20" class="icon" color="red" align-item="center"
                                @click="deleteTeam(item)">mdi-trash-can
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
import axios from "@/axios";

export default {
    data() {
        return {
            dialog: false,
            search: "",
            headers: [
                {
                    text: "Nome da Equipe",
                    align: "start",
                    value: "name",
                },

                { text: "Ações", value: "actions", sortable: false },
            ],

            displayError: {
                display: false,
                message: [],
            },

            page: 1,
            numberOfPages: 0,
            passengers: [],
            loading: true,
            options: {},
            sortBy: "name",

            medicalTeam: {},
            equipes: [],
            receptor: [],
            usuarios: [],
            unidades: [],
            hospital: [],
            message: "",
            id: {},
            snackbar: false,
            loadingBTN: false,
            exibirMensagem: false,
            messageError: "",
        };
    },

    components: {
    },

    created() {
        this.loading = true;
        this.showTeams();
    },

    watch: {
        sortBy: {
            handler() {

            }
        },
        options: {
            handler() {
                this.indoDataTable();
                this.showTeams();
            },
        },
        deep: true,
    },

    methods: {

        //Carrega as coisas ao acessar a tela
        indoDataTable() {
            this.loading = true;
        },
           
        showTeams() {
            if(this.$store.state.profile_id == 1){
            axios.get("/teams").then((response) => {
                this.loading = false;
                this.equipes = response.data.data;
                this.equipes = this.equipes.filter((x) => x.enabled == 1);
            });
            }else{
                const id = this.$store.state.unity_id
                axios.get(`/teams/teamUnity/${id}`).then((response) => {
                this.loading = false;
                this.equipes = response.data
                this.equipes = this.equipes.filter((x) => x.enabled == 1);
            })
            }
        },

        clear() {
            this.name = null,
                this.cpf = null,
                this.birth = null,
                this.mother_name = null
        },

        deleteTeam(item) {
            axios.patch(`/teams/${item.id}`, { enabled: "0" }).then((response) => {
                this.message = response.data.message;
                this.snackbar = true;
                this.loading = true;
                this.showTeams();
            });
        },
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 9px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}


.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>