<template>
    <div>
        <GridEquipeMedica />
    </div>
</template>
<script>
import GridEquipeMedica from "./GridEquipe-medica.vue";

export default {
    data: () => ({

    }),

    components: {
        GridEquipeMedica
    }
}
</script>